import { MemberMenuConfig, MemberPagesConfig } from '../modules/settings/Member'
import { PromoMenuConfig, PromoPagesConfig } from '../modules/settings/promo'
import { MatomoMenuConfig } from '../modules/matomo/modules'
import { TicketMenuConfig } from '../modules/ticketsystem/modules'
import { ShopMenuConfig } from '../modules/shop'
import { monitoringMenuConfig } from '../modules/monitoring/modules'
import { SettingsMenuConfig } from '../modules/settings'
import { AuthPagesConfig } from '../@WUM/templates/default/Auth/modules'
import { MatomoPagesConfig } from '../modules/matomo/modules'
import { TicketPagesConfig } from '../modules/ticketsystem/modules'
import { monitoringPagesConfig } from '../modules/monitoring/modules'
import { ShopRouteConfig } from '../modules/shop'
import { SettingsRouteConfig } from '../modules/settings'
import {
    GermanBusinessDasboard,
    GermanBusinessMenuConfig,
    GermanBusinessPagesConfig
} from '../modules/backoffice/germanbusiness'
import {
    ForeignBusinessDasboard,
    ForeignBusinessMenuConfig,
    ForeignBusinessPagesConfig
} from '../modules/backoffice/foreignbusiness'
import {
    SRODasboard,
    SROMenuConfig,
    SROPagesConfig
} from '../modules/backoffice/sro'
import {
    EwivDasboard,
    EwivMenuConfig,
    EwivPagesConfig
} from '../modules/backoffice/ewiv'
import {
    CustomerDasboard,
    CustomerMenuConfig,
    CustomerPagesConfig
} from '../modules/backoffice/customer'
import {
    ReferalDasboard,
    ReferalMenuConfig,
    ReferalPagesConfig
} from '../modules/backoffice/referal'
import {
    DynamikCalenderDasboard,
    DynamikCalenderMenuSingleConfig,
    DynamikCalenderPagesConfig
} from '../modules/backoffice/calender'
import {
    DynamikInternalNewsDasboard,
    DynamikInternalNewsMenuSingleConfig,
    DynamikInternalNewsPagesConfig
} from '../modules/backoffice/internalNes'
import {
    DynamikTextBlocksDasboard,
    DynamikTextBlocksMenuConfig,
    DynamikTextBlocksPagesConfig
} from '../modules/backoffice/TextBlocksDynamik/modules'
import {
    DynamikMenberDecissionDasboard,
    DynamikMenberDecissionMenuSingleConfig,
    DynamikMenberDecissionPagesConfig
} from '../modules/backoffice/menberDecission'
import {
    DynamikNewsletterDasboard,
    DynamikNewsletterMenuSingleConfig,
    NewsletterPagesConfig
} from "../modules/newsletter";
import {
    DynamikTodolistMenuSingleConfig,
    DynamikTodolistPagesConfig,
    DynamiktTodolistDasboard
} from "../modules/backoffice/todolist";
import {
    DynamikMemberDasboard,
    DynamikMemberMenuSingleConfig,
    DynamikMemberPagesConfig
} from "../modules/backoffice/Member";
const components = {
    MemberMenuConfig: MemberMenuConfig,
    PromoMenuConfig: PromoMenuConfig,
    MemberPagesConfig: MemberPagesConfig,
    PromoPagesConfig: PromoPagesConfig,
    //'CMSMenuConfig': CMSMenuConfig,
    MatomoMenuConfig: MatomoMenuConfig,
    TicketMenuConfig: TicketMenuConfig,
    ShopMenuConfig: ShopMenuConfig,
    monitoringMenuConfig: monitoringMenuConfig,
    SettingsMenuConfig: SettingsMenuConfig,
    AuthPagesConfig: AuthPagesConfig,
    //'CMSRouteConfig':CMSRouteConfig,
    MatomoPagesConfig: MatomoPagesConfig,
    TicketPagesConfig: TicketPagesConfig,
    monitoringPagesConfig: monitoringPagesConfig,
    ShopRouteConfig: ShopRouteConfig,
    SettingsRouteConfig: SettingsRouteConfig,
    GermanBusinessPagesConfig: GermanBusinessPagesConfig,
    GermanBusinessMenuConfig: GermanBusinessMenuConfig,
    ForeignBusinessPagesConfig: ForeignBusinessPagesConfig,
    ForeignBusinessMenuConfig: ForeignBusinessMenuConfig,
    SROPagesConfig: SROPagesConfig,
    SROMenuConfig: SROMenuConfig,
    EwivPagesConfig: EwivPagesConfig,
    EwivMenuConfig: EwivMenuConfig,
    CustomerPagesConfig: CustomerPagesConfig,
    CustomerMenuConfig: CustomerMenuConfig,
    CustomerDasboard: CustomerDasboard,
    GermanBusinessDasboard: GermanBusinessDasboard,
    ForeignBusinessDasboard: ForeignBusinessDasboard,
    SRODasboard: SRODasboard,
    EwivDasboard: EwivDasboard,
    ReferalPagesConfig: ReferalPagesConfig,
    ReferalMenuConfig: ReferalMenuConfig,
    ReferalDasboard: ReferalDasboard,
    CalenderPagesConfig: DynamikCalenderPagesConfig,
    CalenderMenuSingleConfig: DynamikCalenderMenuSingleConfig,
    CalenderDasboard: DynamikCalenderDasboard,
    DynamikInternalNewsPagesConfig: DynamikInternalNewsPagesConfig,
    DynamikInternalNewsMenuSingleConfig: DynamikInternalNewsMenuSingleConfig,
    DynamikInternalNewsDasboard: DynamikInternalNewsDasboard,
    DynamikTextBlocksPagesConfig: DynamikTextBlocksPagesConfig,
    DynamikTextBlocksMenuConfig: DynamikTextBlocksMenuConfig,
    DynamikTextBlocksDasboard: DynamikTextBlocksDasboard,
    DynamikMenberDecissionPagesConfig: DynamikMenberDecissionPagesConfig,
    DynamikMenberDecissionMenuSingleConfig:
        DynamikMenberDecissionMenuSingleConfig,
    DynamikMenberDecissionDasboard: DynamikMenberDecissionDasboard,
    NewsletterPagesConfig: NewsletterPagesConfig,
    DynamikNewsletterMenuSingleConfig: DynamikNewsletterMenuSingleConfig,
    DynamikNewsletterDasboard: DynamikNewsletterDasboard,
    //'DashbaordPagesConfig':DashbaordPagesConfig,
    //'DashbaordMenuConfig':DashbaordMenuConfig
    DynamikTodolistPagesConfig:DynamikTodolistPagesConfig,
    DynamikTodolistMenuSingleConfig:DynamikTodolistMenuSingleConfig,
    DynamiktTodolistDasboard:DynamiktTodolistDasboard,
    DynamikMemberPagesConfig:DynamikMemberPagesConfig,
    DynamikMemberMenuSingleConfig:DynamikMemberMenuSingleConfig,
    DynamikMemberDasboard:DynamikMemberDasboard
}

export default components
