import { IntlMessageValue } from '../../component/const/IntlMessage'
import FormElements from './index'
import React from 'react'

export const newModul = (
    type: any,
    index: any,
    setdeleteElement: any,
    setElements: any,
    Store: any,
    galerie?: any,
    galerieItems?: any
) => {
    const tmp = []

    const elements: any = {
        type: type
    }
    tmp.push(elements)

    const elementsContent = {
        conf: [
            {
                lang: 'de',
                height: '400px'
            },
            {
                lang: 'de',
                height: '200px'
            },
            {
                lang: 'de',
                height: '400px'
            }
        ],
        elements: tmp
    }

    return FormElements(
        elementsContent,
        false,
        false,
        false,
        false,
        true,
        index,
        setdeleteElement,
        setElements,
        Store,
        galerie,
        galerieItems
    )
}
export const Email = (Store: any) => {
    return [
        {
            value: 'headline',
            label: IntlMessageValue('de', 'module.headline', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        view_headline
                    </span>
                </>
            )
        }, //x
        {
            value: 'text',
            label: IntlMessageValue('de', 'module.text', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">text_snippet</span>
                </>
            )
        }, //x
        {
            value: 'image',
            label: IntlMessageValue('de', 'module.image', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">image</span>
                </>
            )
        }, //x
        {
            value: 'video',
            label: 'UM02 - Video',
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        smart_display
                    </span>
                </>
            )
        }, //x
        {
            value: 'divider',
            label: IntlMessageValue('de', 'module.divider', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        border_horizontal
                    </span>
                </>
            )
        }, //x
        {
            value: 'html',
            label: IntlMessageValue('de', 'module.html_modul', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">html</span>
                </>
            )
        } //x
    ]
}

export const WebBuilderNav = (Store: any) => {
    return [...MediaMap(Store), ...TextMap(Store), ...SocialMap(Store)]
}

export const TextMap = (Store: any) => {
    return [
        {
            value: 'text',
            label: IntlMessageValue('de', 'module.text', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">text_snippet</span>
                </>
            )
        }, //x
        {
            value: 'accordion',
            label: IntlMessageValue('de', 'module.accordion', Store),
            icon: (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fill="currentColor"
                            d="M0 4v8h16V4H0zm15 7H1V7h14v4zM0 0h16v3H0V0zm0 13h16v3H0v-3z"
                        />
                    </svg>
                </>
            )
        }, //x
        //{'value': 'text_blocker', 'label': IntlMessageValue('de',"module.text_with_background",Store), 'icon': <></>},
        {
            value: 'headline',
            label: IntlMessageValue('de', 'module.headline', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        view_headline
                    </span>
                </>
            )
        }, //x
        {
            value: 'text_icon',
            label: IntlMessageValue('de', 'module.text_with_icon', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">grid_on</span>
                </>
            )
        }, //x
        {
            value: 'divider',
            label: IntlMessageValue('de', 'module.divider', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        border_horizontal
                    </span>
                </>
            )
        }, //x
        {
            value: 'anchor',
            label: IntlMessageValue('de', 'module.anchor', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">anchor</span>
                </>
            )
        }, //x
        {
            value: 'html',
            label: IntlMessageValue('de', 'module.html_modul', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">html</span>
                </>
            )
        } //x
    ]
}

export const MediaMap = (Store: any) => {
    let Icon_module_customer_steps = (
        <>
            <svg
                width="24"
                height="24"
                version="1.1"
                viewBox="0 0 6.35 6.35"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    transform="matrix(.11497 0 0 .19316 -.243 0)"
                    style={{ fill: '#000000' }}
                >
                    <path
                        d="m19.001 4.2854v14h-14v-14h14m0-2h-14c-1.1 0-2 0.9-2 2v14c0 1.1 0.9 2 2 2h14c1.1 0 2-0.9 2-2v-14c0-1.1-0.9-2-2-2zm-4.86 8.86-3 3.87-2.14-2.59-3 3.86h12z"
                        style={{ fill: '#000000' }}
                    />
                    <path
                        d="m55.001 4.418v14h-14v-14h14m0-2h-14c-1.1 0-2 0.9-2 2v14c0 1.1 0.9 2 2 2h14c1.1 0 2-0.9 2-2v-14c0-1.1-0.9-2-2-2zm-4.86 8.86-3 3.87-2.14-2.59-3 3.86h12z"
                        style={{ fill: '#000000' }}
                    />
                    <path
                        d="m37.001 4.3435v14h-14v-14h14m0-2h-14c-1.1 0-2 0.9-2 2v14c0 1.1 0.9 2 2 2h14c1.1 0 2-0.9 2-2v-14c0-1.1-0.9-2-2-2zm-4.86 8.86-3 3.87-2.14-2.59-3 3.86h12z"
                        style={{ fill: '#000000' }}
                    />
                </g>
                <g
                    transform="matrix(.11046 0 0 .11046 -.38269 3.4393)"
                    style={{ fill: '#000000' }}
                >
                    <path
                        d="m6.4686 17.816h14.571v-2h-14.571zm0 4h14.571v-2h-14.571zm0-8h14.571v-2h-14.571zm0-6v2h14.571v-2z"
                        style={{ fill: '#000000', strokeWidth: '.95431' }}
                    />
                    <path
                        d="m25.203 17.754h14.571v-2h-14.571zm0 4h14.571v-2h-14.571zm0-8h14.571v-2h-14.571zm0-6v2h14.571v-2z"
                        style={{ fill: '#000000', strokeWidth: '.95431' }}
                    />
                    <path
                        d="m43.937 17.799h14.571v-2h-14.571zm0 4h14.571v-2h-14.571zm0-8h14.571v-2h-14.571zm0-6v2h14.571v-2z"
                        style={{ fill: '#000000', strokeWidth: '.95431' }}
                    />
                </g>
            </svg>
        </>
    )

    /*  let Icon_module_content = <>
    <svg width="24" height="24" version="1.1" viewBox="0 0 6.35 6.35" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(.11497 0 0 .19316 -.243 0)" style={{fill:"#000000"}}>
            <g transform="translate(0 4.7204)">
                <path d="m32.767 4.3562v14.496h-26.045v-14.496h26.045m0-2.0708h-26.045c-2.0464 0-3.7208 0.93187-3.7208 2.0708v14.496c0 1.139 1.6743 2.0708 3.7208 2.0708h26.045c2.0464 0 3.7208-0.93187 3.7208-2.0708v-14.496c0-1.139-1.6743-2.0708-3.7208-2.0708zm-9.0414 9.1737-5.5811 4.007-3.9812-2.6817-5.5811 3.9967h22.325z"
                      style={{fill:"#000000",strokeWidth:'.73288'}}/>
                <path d="m55.001 3.4923v7.5196h-14v-7.5196h14m0-1.0742h-14c-1.1 0-2 0.48341-2 1.0742v7.5196c0 0.59083 0.9 1.0742 2 1.0742h14c1.1 0 2-0.48341 2-1.0742v-7.5196c0-0.59083-0.9-1.0742-2-1.0742zm-4.86 4.7589-3 2.0786-2.14-1.3911-3 2.0733h12z"
                      style={{fill:"#000000",strokeWidth:'.73288'}}/>
                <path d="m55.001 12.554v7.5196h-14v-7.5196h14m0-1.0742h-14c-1.1 0-2 0.48341-2 1.0742v7.5196c0 0.59083 0.9 1.0742 2 1.0742h14c1.1 0 2-0.48341 2-1.0742v-7.5196c0-0.59083-0.9-1.0742-2-1.0742zm-4.86 4.7589-3 2.0786-2.14-1.3911-3 2.0733h12z"
                      style={{fill:"#000000",strokeWidth:'.73288'}}/></g></g></svg>
  </>*/

    let Icon_Video_DUO = (
        <>
            <svg
                width="24"
                height="24"
                version="1.1"
                viewBox="0 0 6.35 6.35"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    transform="matrix(.14202 0 0 .11046 -5.5672 1.0731)"
                    style={{ fill: '#000000' }}
                >
                    <path
                        d="m67.441 13.967h14.571v-2h-14.571zm0 4h14.571v-2h-14.571zm0-8h14.571v-2h-14.571zm0-6v2h14.571v-2z"
                        style={{ fill: '#000000', strokeWidth: '.95431' }}
                    />
                </g>
                <g
                    transform="matrix(.14202 0 0 .11046 -5.5672 2.8085)"
                    style={{ fill: '#000000' }}
                >
                    <path
                        d="m67.441 13.967h14.571v-2h-14.571zm0 4h14.571v-2h-14.571zm0-8h14.571v-2h-14.571zm0-6v2h14.571v-2z"
                        style={{ fill: '#000000', strokeWidth: '.95431' }}
                    />
                </g>
                <g
                    transform="matrix(.26458 0 0 .26458 -.065586 -1.4688)"
                    style={{ fill: '#000000' }}
                >
                    <rect width="24" height="24" fill="none" />
                </g>
                <g
                    transform="matrix(.26458 0 0 .26458 -6.2865 -.24378)"
                    style={{ fill: '#000000' }}
                >
                    <g style={{ fill: '#000000' }}>
                        <polygon
                            transform="translate(18.288 .93648)"
                            points="9.5 16.5 16.5 12 9.5 7.5"
                            style={{ fill: '#000000' }}
                        />
                        <path
                            d="m36.144 4.9214h-10.384c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h10.384c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zm0 14.01h-10.384v-12.02h10.384z"
                            style={{ fill: '#000000' }}
                        />
                    </g>
                </g>
            </svg>
        </>
    )

    return [
        {
            value: 'image_title',
            label: IntlMessageValue('de', 'module.hero_image', Store),
            icon: (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fill="currentColor"
                            d="M1 3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3Zm5.5 2h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1Zm-3-2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3ZM3 12.5V9h4a3 3 0 0 0 3-3V3h2.5A2.5 2.5 0 0 1 15 5.5v7c0 .51-.152.983-.414 1.379l-4.384-4.384a1.7 1.7 0 0 0-2.404 0l-4.384 4.384A2.488 2.488 0 0 1 3 12.5Zm9.5-5.998a1.002 1.002 0 1 0-2.004 0a1.002 1.002 0 0 0 2.004 0Zm1.379 8.084l-4.384-4.384a.7.7 0 0 0-.99 0l-4.384 4.384c.396.262.87.414 1.379.414h7c.51 0 .983-.152 1.379-.414Z"
                        />
                    </svg>
                </>
            )
        }, // x
        {
            value: 'module_customer_steps',
            label: IntlMessageValue('de', 'module.three_blocks', Store),
            icon: Icon_module_customer_steps
        }, //x
        {
            value: 'media_text',
            label: IntlMessageValue('de', 'module.text_with_image', Store),
            icon: (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M22 13h-8v-2h8v2m0-6h-8v2h8V7m-8 10h8v-2h-8v2m-2-8v6c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2m-1.5 6l-2.2-3l-1.8 2.3l-1.2-1.5L3.5 15h7Z"
                        />
                    </svg>
                </>
            )
        }, //x
        {
            value: 'slider',
            label: IntlMessageValue('de', 'module.slider', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        view_carousel
                    </span>
                </>
            )
        }, //x
        {
            value: 'ung_masonry',
            label: 'UMG01 - Masonry',
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        view_carousel
                    </span>
                </>
            )
        }, //x
        {
            value: 'card',
            label: 'card',
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        view_carousel
                    </span>
                </>
            )
        }, //x
        //{'value': 'module_content', 'label': IntlMessageValue('de',"module.editorial",Store), 'icon':Icon_module_content},
        {
            value: 'image',
            label: IntlMessageValue('de', 'module.image', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">image</span>
                </>
            )
        }, //x
        {
            value: 'video',
            label: 'UM02 - Video',
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        smart_display
                    </span>
                </>
            )
        }, //x
        {
            value: 'video_duo',
            label: IntlMessageValue(
                'de',
                'content.element.module_video_duo.type',
                Store
            ),
            icon: Icon_Video_DUO
        },
        {
            value: 'hexagon',
            label: 'Hexagon',
            icon: <span className="material-icons vmiddle">image</span>
        }
    ]
}

export const SocialMap = (Store: any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'ProductCatalog':
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            return [
                {
                    value: 'cta',
                    label: IntlMessageValue('de', 'module.cta', Store),
                    icon: (
                        <>
                            <span className="material-icons vmiddle">
                                call_to_action
                            </span>
                        </>
                    )
                }
            ]
        default:
            return [
                {
                    value: 'cta',
                    label: IntlMessageValue('de', 'module.cta', Store),
                    icon: (
                        <>
                            <span className="material-icons vmiddle">
                                call_to_action
                            </span>
                        </>
                    )
                }
            ]
    }
}
export const Widgets = (Store: any) => {
    return [
        {
            value: 'maps',
            label: 'Map',
            icon: <span className="material-icons vmiddle">image</span>
        },
        {
            value: 'search_result',
            label: IntlMessageValue('de', 'module.search', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        manage_search
                    </span>
                </>
            )
        },
        {
            value: 'blog_teaser',
            label: IntlMessageValue('de', 'module.blog', Store),
            icon: (
                <>
                    <span className="material-icons vmiddle">post_add</span>
                </>
            )
        }, //x
        {
            value: 'magazin',
            label: 'Magazin',
            icon: (
                <>
                    <span className="material-icons vmiddle">newspaper</span>
                </>
            )
        }, //x
        {
            value: 'formular',
            label: 'Form',
            icon: (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 36 36"
                    >
                        <path
                            fill="currentColor"
                            d="M21 12H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1ZM8 10h12V7.94H8Z"
                            className="clr-i-outline clr-i-outline-path-1"
                        />
                        <path
                            fill="currentColor"
                            d="M21 14.08H7a1 1 0 0 0-1 1V19a1 1 0 0 0 1 1h11.36L22 16.3v-1.22a1 1 0 0 0-1-1ZM20 18H8v-2h12Z"
                            className="clr-i-outline clr-i-outline-path-2"
                        />
                        <path
                            fill="currentColor"
                            d="M11.06 31.51v-.06l.32-1.39H4V4h20v10.25l2-1.89V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h8a3.44 3.44 0 0 1 .06-.49Z"
                            className="clr-i-outline clr-i-outline-path-3"
                        />
                        <path
                            fill="currentColor"
                            d="m22 19.17l-.78.79a1 1 0 0 0 .78-.79Z"
                            className="clr-i-outline clr-i-outline-path-4"
                        />
                        <path
                            fill="currentColor"
                            d="M6 26.94a1 1 0 0 0 1 1h4.84l.3-1.3l.13-.55v-.05H8V24h6.34l2-2H7a1 1 0 0 0-1 1Z"
                            className="clr-i-outline clr-i-outline-path-5"
                        />
                        <path
                            fill="currentColor"
                            d="m33.49 16.67l-3.37-3.37a1.61 1.61 0 0 0-2.28 0L14.13 27.09L13 31.9a1.61 1.61 0 0 0 1.26 1.9a1.55 1.55 0 0 0 .31 0a1.15 1.15 0 0 0 .37 0l4.85-1.07L33.49 19a1.6 1.6 0 0 0 0-2.27ZM18.77 30.91l-3.66.81l.89-3.63L26.28 17.7l2.82 2.82Zm11.46-11.52l-2.82-2.82L29 15l2.84 2.84Z"
                            className="clr-i-outline clr-i-outline-path-6"
                        />
                        <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                </>
            )
        },
        {
            value: 'slider_start',
            label: 'Slider',
            icon: (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 32 32"
                    >
                        <path
                            fill="currentColor"
                            d="M22 26H10a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2zM10 8v16h12V8zM4 24H0v-2h4V10H0V8h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zm28 0h-4a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h4v2h-4v12h4z"
                        />
                    </svg>
                </>
            )
        },
        {
            value: 'carouselItem_start',
            label: 'SliderItem',
            icon: (
                <>
                    <span className="material-icons vmiddle">
                        recent_actors
                    </span>
                </>
            )
        },
        {
            value: 'row_start',
            label: 'UM19 Row',
            icon: (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                    >
                        <g transform="rotate(-90 12 12)">
                            <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M20 6v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1zm-8 9v4m2-2h-4"
                            />
                        </g>
                    </svg>
                </>
            )
        }, //x
        {
            value: 'social_media',
            label: IntlMessageValue(
                'de',
                'content.element.module_social_media.type',
                Store
            ),
            icon: (
                <>
                    <span className="material-icons vmiddle">groups</span>
                </>
            )
        },
        {
            value: 'menu',
            label: 'Menu',
            icon: (
                <>
                    <span className="material-icons vmiddle">menu</span>
                </>
            )
        },
        {
            value: 'calender',
            label: 'calender',
            icon: (
                <>
                    <span className="material-icons vmiddle">menu</span>
                </>
            )
        }
    ]
}

export const Layout = (Store: any) => {
    return [
        {
            value: 'container',
            label: 'Add Layout',
            icon: (
                <>
                    <span className="material-icons vmiddle">add</span>
                </>
            )
        }
        /*{'value': 'container', 'col': '1', 'row':'3', 'label': '1/3 Layout', 'icon': <><span className="material-icons vmiddle">table_rows</span></>},
        {'value': 'container', 'col': '1', 'row':'4', 'label': '1/4 Layout', 'icon': <><span className="material-icons vmiddle" style={{transform: 'rotate(90deg)'}}>calendar_view_week</span></>},
        {'value': 'container', 'col': '2', 'row':'1', 'label': '2/1 Layout', 'icon': <><span className="material-icons vmiddle" style={{transform: 'rotate(90deg)'}}>view_stream</span></>},
        {'value': 'container', 'col': '2', 'row':'2', 'label': '2/2 Layout', 'icon': <><span className="material-icons vmiddle">grid_view</span></>},
        {'value': 'container', 'col': '3', 'row':'1', 'label': '3/1 Layout', 'icon': <><span className="material-icons vmiddle">view_column</span></>},
        {'value': 'container', 'col': '3', 'row':'2', 'label': '3/2 Layout', 'icon': <><span className="material-icons vmiddle">view_module</span></>},
        {'value': 'container', 'col': '3', 'row':'3', 'label': '3/3 Layout', 'icon': <><span className="material-icons vmiddle">grid_on</span></>},
        {'value': 'container', 'col': '4', 'row':'1', 'label': '4/1 Layout', 'icon': <><span className="material-icons vmiddle">calendar_view_week</span></>}*/
    ]
}
