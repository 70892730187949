import DynamikOverview from '../../dynamikModul/Modules/Pages/overview'
import React from 'react'
import {
    ResponseOverview,
    ResponseDelete,
    ResponseDetail,
    AllCustomer
} from './API/response/Type/customizer'
import DynamikDeleted from '../../dynamikModul/Modules/Pages/deleted'
import DynamikDetail from '../../dynamikModul/Modules/Pages/detail'
import DynamikNew from '../../dynamikModul/Modules/Pages/new'
import CardJSX from './configuration/card'
import SearchConfig from './configuration/SearchConfig'
import { destination, link } from './helper/const'
import table from './configuration/table'
import Forms from './configuration/Form'

export const ReferalPagesConfig = (value: any) => {
    return [
        {
            path: '/referal/overview/:page',
            elements: (
                <DynamikOverview
                    value={value}
                    table={table}
                    destination={destination}
                    state={'referal'}
                    SearchConfig={SearchConfig(destination)}
                    CardsConfiguration={CardJSX(link, destination)}
                    response={ResponseOverview}
                    buttonText={'Referal '}
                    nonewButton={true}
                    dynamik={true}
                    dynamikLocale={AllCustomer}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/referal/Delete/:page/:deleteID',
            elements: (
                <DynamikDeleted
                    value={value}
                    reponse={ResponseDelete}
                    namespace={destination}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/referal/new/new',
            elements: (
                <DynamikNew
                    value={value}
                    forms={Forms}
                    namespace={'/' + destination + '/overview/1'}
                    saveRoute={'referredCustomer/0/'}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/referal/new/:page',
            elements: (
                <DynamikNew
                    value={value}
                    forms={Forms}
                    namespace={'/' + destination + '/overview/1'}
                    saveRoute={'referredCustomer/0/'}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/referal/Detail/:id',
            elements: (
                <DynamikDetail
                    value={value}
                    forms={Forms}
                    response={ResponseDetail}
                    saveRoute={'referredCustomer/'}
                />
            ),
            roles: ['superadmin', 'admin']
        }
    ]
}

export const ReferalMenuConfig = (value: any) => {
    return [
        {
            name: 'Referalsystem',
            route: '/referal/overview/1',
            icon: 'share',
            roles: ['superadmin', 'admin']
        }
    ]
}

export const ReferalDasboard = () => [
    {
        title: 'Referalsystem',
        href: '/referal/overview/1',
        undertitle: 'Referalsystem',
        icon: 'share',
        text: 'Referalsystem',
        roles: ['superadmin', 'admin']
    }
]
