import IntlMessage, { IntlMessageValue } from '../../const/IntlMessage'
import React from 'react'
//import {AuthUser} from '../../../../../types/models/AuthUser';

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    let cutDate =
        newElement === false
            ? response.translation.de.published_at !== undefined &&
              response.translation.de.published_at !== null
                ? response.translation.de.published_at.split(' ')[0]
                : ' '
            : ' '

    const titel = (
        <>
            <h2>
                <IntlMessage
                    Store={Store}
                    messageId="configuration.home.title"
                />
            </h2>
        </>
    )

    const description = (
        <>
            <p>
                <IntlMessage
                    Store={Store}
                    messageId="configuration.home.description"
                />
            </p>
        </>
    )

    const dataColOne = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'type',
            value: newElement === false ? response.type : ''
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'layout',
            value: newElement === false ? response.layout : ''
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'translations[locale]',
            value: newElement === false ? response.translation.de.locale : 'de'
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'id',
            value: newElement === false ? response.id : ''
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'translations[id]',
            value: newElement === false ? response.translation.de.id : ''
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'translations[parent]',
            value: newElement === false ? response.translation.de.parent : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.title" />,
            required: true,
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'common.provide_pagetitle',
                Store
            ),
            name: 'translations[title]',
            value: newElement === false ? response.translation.de.title : ''
        },
        {
            formType: 'input',
            type: 'text',
            label: (
                <IntlMessage Store={Store} messageId="form.label.identifier" />
            ),
            placeholder: IntlMessageValue(
                'de',
                'form.placeholder.identifier',
                Store
            ),
            name: 'identifier',
            value: newElement === false ? response.identifier : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.released" />,
            type: 'date',
            placeholder: 'xx.xx.xxxx',
            name: 'published_at',
            value: cutDate
        }
    ]

    const dataColtwo = [
        {
            formType: 'input',
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'form.placeholder.subject',
                Store
            ),
            label: <IntlMessage Store={Store} messageId="form.label.subject" />,
            name: 'subject',
            value: newElement === false ? response.subject : ''
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="form.label.layout" />,
            name: 'identifier',
            selected: newElement === false ? response.layout : 0,
            options: [
                {
                    label: '',
                    value: '0'
                },
                {
                    label: IntlMessageValue(
                        'de',
                        'form.option.field.label.layout.default',
                        Store
                    ),
                    value: '1'
                },
                {
                    label: IntlMessageValue(
                        'de',
                        'form.option.field.label.layout.provision',
                        Store
                    ),
                    value: '2'
                },
                {
                    label: IntlMessageValue(
                        'de',
                        'form.option.field.label.layout.shipping',
                        Store
                    ),
                    value: '3'
                },
                {
                    label: IntlMessageValue(
                        'de',
                        'form.option.field.label.layout.order',
                        Store
                    ),
                    value: '4'
                }
            ]
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.visible" />,
            name: 'visible',
            selected: newElement === false ? response.visible : 0,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                }
            ]
        }
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOne
    } else if (conf === 'dataColtwo') {
        return dataColtwo
    }
}

export default configuration
