import { AuthPagesConfig } from '../@WUM/templates/default/Auth/modules'
import { CMSRouteConfig } from '../modules/CMS'
import { MatomoPagesConfig } from '../modules/matomo/modules'
import { TicketPagesConfig } from '../modules/ticketsystem/modules'
import { monitoringPagesConfig } from '../modules/monitoring/modules'
import { ShopRouteConfig } from '../modules/shop'
import { SettingsRouteConfig } from '../modules/settings'
import MergeTwoObjecttoArray from '../@WUM/core/component/const/MergeTwoObjecttoArray'
import components from './RegisterComponents'
import { DashbaordPagesConfig } from '../modules/CMS/dashboard/modules'
import { useSelector } from 'react-redux'
import { CalenderPagesConfig } from '../modules/calender'
import { NewsletterPagesConfig } from '../modules/newsletter'

export const RegisterRoute = (value: any) => {
    const Store = useSelector((state: any) => state)

    let TMPRegister = []
    let TMPRegisterIndividual
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...CalenderPagesConfig(value),
                ...NewsletterPagesConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...SettingsRouteConfig(value)
            ]
            break
        case 'CMSwithMatomo':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...CalenderPagesConfig(value),
                ...NewsletterPagesConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...MatomoPagesConfig(value),
                ...SettingsRouteConfig(value)
            ]
            break
        case 'SHOP':
        case 'ProductCatalog':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...CalenderPagesConfig(value),
                ...NewsletterPagesConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...ShopRouteConfig(value),
                ...SettingsRouteConfig(value)
            ]
            break
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...CalenderPagesConfig(value),
                ...NewsletterPagesConfig(value),
                ...MatomoPagesConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...ShopRouteConfig(value),
                ...SettingsRouteConfig(value)
            ]
            break
        default:
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...CalenderPagesConfig(value),
                ...NewsletterPagesConfig(value),
                ...MatomoPagesConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...ShopRouteConfig(value),
                ...SettingsRouteConfig(value)
            ]
    }
    TMPRegisterIndividual = []

    if (
        process.env.REACT_APP_DB_MODULS !== undefined &&
        Store.dynamikModuls.PagesModuls.length > 0 &&
        !Store.dynamikModuls.PagesModuls[0].hasOwnProperty('msg')
    ) {
        for (let x = 0; x < Store.dynamikModuls.PagesModuls.length; x++) {
            let search = Store.dynamikModuls.PagesModuls[x].namespace

            TMPRegisterIndividual = MergeTwoObjecttoArray(
                TMPRegisterIndividual,
                /* @ts-ignore */
                components[search](value)
            )
        }
    }
    TMPRegisterIndividual = MergeTwoObjecttoArray(
        TMPRegisterIndividual,
        DashbaordPagesConfig(value)
    )

    if (process.env.REACT_APP_DB_MODULS === undefined) {
        return [...TMPRegister]
    } else {
        if (localStorage.getItem('userRole') === 'superadmin') {
            return [...TMPRegister, ...TMPRegisterIndividual]
        } else {
            return [...TMPRegisterIndividual]
        }
    }
}
